<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col cols="12" md="6"> </b-col>
          <!-- Search -->
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="openMatris">
                <span class="text-nowrap">{{ $t("show") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="12" sm="12" md="6" class="">
            <div class="d-none d-md-flex justify-content-between flex-wrap">
              <b-badge variant="primary" class="">
                <span class="h4 text-white"> {{ $t("ingredients") }}</span>
              </b-badge>
              <b-button
                size="sm"
                variant="success"
                @click="selectAll(hammaddeler, 'hammadde')"
              >
                <span>{{ $t("selectAll") }}</span>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="removeAll(hammaddeler, 'hammadde')"
              >
                <span>{{ $t("removeAll") }}</span>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" sm="12" offset-lg="1" md="6" lg="5" class="">
            <div class="d-none d-md-flex justify-content-between flex-wrap">
              <b-badge variant="primary" class="">
                <span class="h4 text-white"> {{ $t("nutrients") }}</span>
              </b-badge>
              <b-button
                size="sm"
                variant="success"
                @click="selectAll(besinmaddeleri, 'besinmaddesi')"
              >
                <span>{{ $t("selectAll") }}</span>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="removeAll(besinmaddeleri, 'besinmaddesi')"
              >
                <span>{{ $t("removeAll") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" sm="12" md="6" class="border p-1 rounded box">
            <div class="d-flex d-md-none justify-content-between flex-wrap">
              <b-badge variant="primary" class="">
                <span class="h4 text-white"> {{ $t("ingredients") }}</span>
              </b-badge>
              <b-button
                size="sm"
                variant="success"
                @click="selectAll(hammaddeler, 'hammadde')"
              >
                <span>{{ $t("selectAll") }}</span>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="removeAll(hammaddeler, 'hammadde')"
              >
                <span>{{ $t("removeAll") }}</span>
              </b-button>
            </div>
            <div v-for="i in hammaddeler" :key="i.ikey" class="mb-1">
              <input
                :id="'i' + i.ikey"
                type="checkbox"
                class="form-check-input hammadde-checkbox"
                @change="i.isselected = !i.isselected"
              />
              <label class="form-check-label ml-3" :for="'i' + i.ikey">
                <small> ({{ i.ikey }}) </small>
                <span class="checkbox-span">{{ i.ingredientname }}</span>
              </label>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="12"
            offset-lg="1"
            md="6"
            lg="5"
            class="border p-1 rounded box"
          >
            <div class="d-flex d-md-none justify-content-between flex-wrap">
              <b-badge variant="primary" class="">
                <span class="h4 text-white"> {{ $t("nutrients") }}</span>
              </b-badge>
              <b-button
                size="sm"
                variant="success"
                @click="selectAll(besinmaddeleri, 'besinmaddesi')"
              >
                <span>{{ $t("selectAll") }}</span>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="removeAll(besinmaddeleri, 'besinmaddesi')"
              >
                <span>{{ $t("removeAll") }}</span>
              </b-button>
            </div>
            <div v-for="b in besinmaddeleri" :key="b.nkey" class="mb-1">
              <input
                :id="b.nkey"
                type="checkbox"
                class="form-check-input besinmaddesi-checkbox"
                @change="b.isselected = !b.isselected"
              />
              <label class="form-check-label ml-3" :for="b.nkey">
                <small> ({{ b.nkey }}) </small>
                <span class="checkbox-span">{{ b.nutrientname }}</span>
              </label>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal id="matris" ref="matrisModal" title="Matris" size="xl" ok-only>
      <b-card no-body class="">
        <div class="mh-full">
          <b-table
            :responsive="true"
            sticky-header="true"
            :no-border-collapse="true"
            :items="matris"
            :fields="tableFields"
          >
          </b-table>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BButton,
  BTable,
  BModal,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BTable,
    BModal,
  },
  data() {
    return {
      selectedHammadde: [],
      selectedBesinmadde: [],
      hammaddeler: [],
      besinmaddeleri: [],
      matris: null,
      tableFields: [],
    };
  },
  mounted() {
    this.getHammaddeler();
    this.getBesinmaddeleri();
  },
  methods: {
    selectAll(value, type) {
      value.forEach((b) => {
        b.isselected = true;
      });
      document.querySelectorAll(`.${type}-checkbox`).forEach((b) => {
        b.checked = true;
      });
    },
    removeAll(value, type) {
      value.forEach((b) => {
        b.isselected = false;
      });
      document.querySelectorAll(`.${type}-checkbox`).forEach((b) => {
        b.checked = false;
      });
    },
    async openMatris() {
      this.selectedHammadde = this.hammaddeler
        .filter((x) => x.isselected == true)
        .map((x) => x.ikey);
      this.selectedBesinmadde = this.besinmaddeleri
        .filter((x) => x.isselected == true)
        .map((x) => x.nkey);
      const { data } = await this.$http.get("/Nutrients/GetMatris", {
        headers: {
          plantid: this.$store.state.app.selectedPlantId,
          ikeys: this.selectedHammadde.join(","),
          nkeys: this.selectedBesinmadde.join(","),
        },
      });
      this.matris = data.resultdata;

      Object.keys(this.matris[0]).map((x) => {
        const firstLetterUpper = x.charAt(0).toUpperCase() + x.slice(1);
        if (x == "hammadde")
          this.tableFields.unshift({
            key: x,
            stickyColumn: true,
            isRowHeader: true,
            sortable: true,
            label: firstLetterUpper,
          });
        else if (x.toLowerCase() == "hid")
          this.tableFields.unshift({
            key: x,
            sortable: true,
            label: firstLetterUpper,
          });
        else
          this.tableFields.push({
            key: x,
            sortable: true,
            label: firstLetterUpper,
          });
      });

      //openModal
      this.$refs.matrisModal.show();
    },
    async getHammaddeler() {
      const { data } = await this.$http.get(
        "/Ingredients/GetIngredientsforMatris",
        { headers: { plantid: this.$store.state.app.selectedPlantId } }
      );
      this.hammaddeler = data.resultdata;
    },
    async getBesinmaddeleri() {
      const { data } = await this.$http.get("/Nutrients/GetNutrientsforMatris");
      this.besinmaddeleri = data.resultdata;
    },
  },
};
</script>

<style>
.form-custom-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-custom-control + .form-custom-control {
  margin-top: 1em;
}

.form-custom-control--disabled {
  color: var(--form-custom-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.55em;
  height: 1.55em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0) translateX(-1em);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-custom-control-color);
  /* Windows High Contrast Mode */
  background-color: black;
}

input[type="checkbox"]:checked::before {
  transform: scale(1) translateX(0);
}

input[type="checkbox"]:disabled {
  --form-custom-control-color: var(--form-custom-control-disabled);

  color: var(--form-custom-control-disabled);
  cursor: not-allowed;
}
.checkbox-span {
  font-size: 14px;
  user-select: none;
}
.box {
  padding: 2px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.mh-full {
  min-width: calc(100vw - 300px);
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.b-table-sticky-header {
  max-height: calc(100vh - 350px);
}
</style>
